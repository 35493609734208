@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}



iframe{
  display: none;
}

/* p{
  animation: akdknsa 1s alternate-reverse;
} */

@keyframes circle-left-animation {
 0% {
  left: 0;
 }
 50%{
  left: 0;
  border-radius: 0;
 }
 100% {
  left: 0;
  border-radius: 0;
  width: 50%;
  height: 100%;
 }
}
@keyframes circle-right-animation {
  0% {
   right: 0;
  }
  50%{
    right: 0;
   border-radius: 0;
  }
  100% {
    right: 0;
   border-radius: 0;
   width: 50%;
   height: 100%;
  }
 }

 @keyframes shine {
  to {
    background-position: 200% center;
  }
}


@keyframes iconLightUp {
  0% {
    filter: brightness(0.7);
    transform: scale(1);
  }
  50% {
    filter: brightness(1.4);
    transform: scale(1.2);
  }
  100% {
    filter: brightness(1);
    transform: scale(1);
  }
}

@keyframes sidebarOpen {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.sidebar {
  animation: sidebarOpen 1s forwards;
}

.icon {
  opacity: 0;
  animation: iconLightUp 0.5s forwards;
}
