.container-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  justify-content: center;
} 
.cube{
  position : relative;
  width : 150px;
  height : 150px;
  transform-style : preserve-3d;
  transform : rotateX(-30deg);
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
      transform: rotateX(-30deg) rotateY(0deg);
  }
  100% {
      transform: rotateX(-30deg) rotateY(360deg);
  }
}

.cube div{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.cube div span{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(#151515 , #D0B05C); */
  transform: rotateY(calc(90deg * var(--i))) translateZ(75px);
  animation: wallAni 2s linear infinite;
}

@keyframes wallAni {
  0% {
      transform: rotateY(calc(90deg * var(--i))) translateZ(150px);
  }
  50% {
      transform: rotateY(calc(90deg * var(--i))) translateZ(75px);
  }
  100%{
      transform: rotateY(calc(90deg * var(--i))) translateZ(150px);
  }
}



.top {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  background: #222;
  transform: rotateX(90deg) translateZ(75px);
  animation: topAni 2s linear infinite;
}

@keyframes topAni {
  0% {
      transform: rotateX(90deg) translateZ(150px);
  }

  50% {
      transform: rotateX(90deg) translateZ(75px);
  }

  100% {
      transform: rotateX(90deg) translateZ(150px);
  }
}


.top::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  background: #D0B05C;
  transform: translateZ(-150px);
  animation: bottomAni 2s linear infinite;
  box-shadow: 
      0 0 120px rgba(0,255,0,0.2),
      0 0 200px rgba(0,255,0,0.4),
      0 0 300px rgba(0,255,0,0.6),
      0 0 400px rgba(0,255,0,0.8),
      0 0 500px rgba(0,255,0,1),
}



@keyframes bottomAni {
  0% {
      transform: translateZ(-300px);
  }

  50% {
      transform: translateZ(-150px);
  }

  100% {
      transform: translateZ(-300px);
  }
}


.loader-container {
  text-align: center;
}

.diamond {
  fill: transparent;
  stroke: #c87f84;
  stroke-width: 2;
  animation: drawDiamond 0.1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
}

.text {
  font-family: Arial, sans-serif;
  font-size: 36px;
  fill: transparent;
  stroke: #c87f84;
  stroke-width: 2;
  stroke-dasharray: 160;
  stroke-dashoffset: 160;
  animation: drawText 0.2s cubic-bezier(0.42, 0, 0.58, 1) forwards ,
             revealText 0.2s cubic-bezier(0.42, 0, 0.58, 1) 0.5s forwards ,
             colorizeText 0.2s cubic-bezier(0.42, 0, 0.58, 1) 0.5s forwards ;
  transform-origin: center;
}

#letterG {
  animation-delay: 0.2s;
}

#letterO {
  animation-delay: 0.4s;
}

#letterD {
  animation-delay: 0.6s;
}

#letterI {
  animation-delay: 0.8s;
}

#letterV {
  animation-delay: 1s;
}

#letterA {
  animation-delay: 1.2s;
}

@keyframes drawText {
  to {
      stroke-dashoffset: 0;
  }
}

@keyframes revealText {
  from {
      fill-opacity: 0;
  }
  to {
      fill-opacity: 1;
  }
}

@keyframes colorizeText {
  to {
      fill: #fff;
  }
}

@keyframes drawDiamond {
  to {
      stroke-dashoffset: 0;
  }
}
